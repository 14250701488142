import React from "react";
import { Grid } from "@nextui-org/react";
import LeftCard from "./messenger/leftCard";
import RightCard from "./messenger/rightCard";

const Messenger = () => {
  const { leftCard, currentChat, messages, setMessages } = LeftCard();
  return (
    <div
      style={{
        padding: "5px",
        backgroundColor: "#202225",
        height: "100vh",
      }}
    >
      <Grid.Container gap={1} justify="center">
        <Grid xs={4}>{leftCard}</Grid>
        <Grid xs={8}>
          <RightCard {...{ currentChat, messages, setMessages }} />
        </Grid>
      </Grid.Container>
    </div>
  );
};

export default Messenger;
