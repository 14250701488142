import { createContext, useState } from "react";

const ConversationContext = createContext();

export const ConversationProvider = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const addConversation = (conver) => {
    if (!conversations.includes(conver)) {
      setConversations([...conversations, conver]);
    }
  };

  return (
    <ConversationContext.Provider
      value={{ conversations, setConversations, addConversation }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export default ConversationContext;
