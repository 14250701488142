import * as React from "react";
import image from "./../assets/images/background.jpg";
import { useState, useEffect, useRef } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useInput from "../hooks/useInput";
import useToggle from "../hooks/useToggle";
import axios from "../api/axios";
import {
  Link as Lin,
  Button,
  Card,
  Grid,
  Text,
  Input,
  Spacer,
  Container,
} from "@nextui-org/react";

const LOGIN_URL = "/auth";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/messenger";

  // const userRef = useRef();
  const errRef = useRef();

  const [user, resetUser, userAttribs] = useInput("user", "");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [check, toggleCheck] = useToggle("persist", true);

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  // resetUser();

  const handleSubmit = async (e) => {
    e?.preventDefault();
    console.log(user, pwd);
    try {
      setDisabled(true);
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const userId = response?.data?.userId;
      const username = response?.data?.username;
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const friends = response?.data?.friends;
      const friendRequests = response?.data?.friendRequests;
      setAuth({
        userId,
        username,
        roles,
        friends,
        friendRequests,
        accessToken,
      });
      resetUser();
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      setDisabled(false);
      console.log(err);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Username or Password incorrect");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  return (
    <div
      className="div"
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        height: "100vh",
        position: "static",
      }}
    >
      <div
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            msTransform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Container>
            <Card
              css={{
                padding: "$15",
                margin: "auto",
                justifySelf: "center",
                maxWidth: "fit-content",
                backgroundColor: "#202225",
              }}
            >
              <Card.Header>
                <Grid.Container justify="center">
                  <Text h1>Sign In</Text>
                </Grid.Container>
              </Card.Header>
              <Card.Body>
                <Grid.Container gap={0} justify="center">
                  <Grid>
                    <Text color="error">{errMsg}</Text>
                  </Grid>
                </Grid.Container>
                <Grid.Container gap={2} justify="center">
                  <Grid>
                    <Input
                      css={{ width: "$60" }}
                      id="username"
                      {...userAttribs}
                      label="Username"
                      onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                    />
                  </Grid>
                </Grid.Container>
                <Grid.Container gap={2} justify="center">
                  <Grid>
                    <Input.Password
                      css={{ width: "$60" }}
                      id="password"
                      onChange={(e) => setPwd(e.target.value)}
                      label="Password"
                      onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                    />
                  </Grid>
                </Grid.Container>
                {/* <Grid.Container>
              <Spacer y={1.6} />
              <Grid>
                <Link css={{ fontSize: "$xs" }} href="#">
                  Forgot Password
                </Link>
              </Grid>
            </Grid.Container> */}
                <Spacer y={0.6} />
                <Grid.Container gap={2} justify="center">
                  <Grid>
                    <Button disabled={disabled} onClick={handleSubmit}>
                      Sign In
                    </Button>
                  </Grid>
                </Grid.Container>
                <Grid.Container gap={2} justify="center">
                  <Grid>
                    <Lin css={{ fontSize: "$xs" }} href="/register">
                      Register Now
                    </Lin>
                  </Grid>
                </Grid.Container>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Login;
