import React from "react";
import { Card } from "@nextui-org/react";

const Message = ({ own, message }) => {
  return (
    <div
      style={{
        maxWidth: "80vh",
      }}
    >
      <Card variant="shadow" css={{ $$cardColor: "#10253E" }}>
        <div
          style={{
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingRight: "15px",
            paddingLeft: "15px",
          }}
        >
          {message.text}
        </div>
      </Card>
    </div>
  );
};

export default Message;
