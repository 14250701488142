import React from "react";
import { useState, useEffect, useContext } from "react";
import Friend from "./friend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import Request from "./requests";
import {
  faSearch,
  faUserGroup,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  Text,
  Input,
  Row,
  Checkbox,
  Spacer,
  Table,
  useAsyncList,
} from "@nextui-org/react";
import Unfriend from "./unfriend";

const Friends = () => {
  const { auth } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const friendHandler = () => {
    setMode(1);
    setVisible(true);
  };
  const [mode, setMode] = useState(1);
  const [success, setSuccess] = useState(false);
  const closeHandler = () => {
    setVisible(false);

    console.log("closed");
  };

  const [friendRequests, setFriendRequests] = useState([]);
  const setReq = async () => {
    await console.log(friendRequests);
  };

  const getuserDetails = async () => {
    try {
      const res = await axios.get("/api/users?userId=" + auth.userId);
      setFriendRequests(res.data.friendRequests);
      console.log(res.data.friendRequests);
      console.log(friendRequests);
    } catch (err) {
      console.log(err);
    }
  };

  const reqButtonHandler = () => {
    getuserDetails();
    setMode(3);
  };

  const columns = [
    { name: "Name", uid: "name" },
    { name: "Unfriend", uid: "unfriend" },
  ];
  async function load({ signal, cursor }) {
    // If no cursor is available, then we're loading the first page.
    // Otherwise, the cursor is the next URL to load, as returned from the previous page.
    const res = await fetch(
      cursor || "https://swapi.py4e.com/api/people/?search=",
      { signal }
    );
    const json = await res.json();
    return {
      items: json.results,
      cursor: json.next,
    };
  }

  const list = useAsyncList({ load });

  const [input, setInput] = useState("");
  const [user, setUser] = useState(null);
  const [userID, setID] = useState("null");

  const [friendList, setFriendList] = useState([]);
  useEffect(() => {
    const getFriendList = async () => {
      try {
        const res = await axios.get("/api/users?userId=" + auth.userId);
        setFriendList(res.data.friends);
      } catch (err) {
        console.log(err);
      }
    };
    getFriendList();
  }, [visible, mode]);

  useEffect(() => {
    setUser(null);
  }, [mode]);

  const getUser = async () => {
    try {
      const res = await axios("/api/users?username=" + input.trim());
      setUser(res.data);
      setID(res.data._id);
    } catch (err) {
      setUser(null);
      console.log(err);
    }
  };

  return {
    friendHandler,
    friends: (
      <div>
        <>
          <Modal
            closeButton
            width="60vh"
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
            css={{ height: "80vh" }}
          >
            <Modal.Header>
              <Button.Group size="md" flat>
                <Button onClick={() => setMode(1)}>My Friends</Button>
                <Button onClick={() => setMode(2)}>Add Friends</Button>
                <Button onClick={() => reqButtonHandler()}>
                  Friend Requests
                </Button>
              </Button.Group>
            </Modal.Header>
            {mode === 1 ? (
              <>
                <Modal.Body>
                  <Row justify="center">
                    <Text h3>My Friends</Text>
                  </Row>
                  {/* <Input
                    clearable
                    bordered
                    fullWidth
                    color="primary"
                    placeholder="Search friends"
                  /> */}
                  {friendList.length === 0 ? (
                    <>
                      <div
                        style={{
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            margin: "0",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            msTransform: "translate(-50%, -50%)",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <Text h4>No Friends Found</Text>
                        </div>
                      </div>
                    </>
                  ) : (
                    friendList.map((c) => (
                      <div>
                        <Unfriend userId={c} />
                      </div>
                    ))
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button auto flat color="error" onClick={closeHandler}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            ) : mode === 2 ? (
              <>
                <Modal.Body>
                  <Row justify="center">
                    <Text h3>Add Friends</Text>
                  </Row>
                  <Input
                    clearable
                    bordered
                    fullWidth
                    onKeyPress={(e) => e.key === "Enter" && getUser()}
                    onChange={(e) => setInput(e.target.value)}
                    color="primary"
                    placeholder="Search Username"
                    contentRight={
                      <Button
                        onClick={() => getUser()}
                        light
                        auto
                        icon={<FontAwesomeIcon icon={faSearch} />}
                      />
                    }
                  />

                  {user && userID !== auth.userId ? (
                    <>
                      <Friend
                        user={user}
                        friendList={friendList}
                        setSuccess={setSuccess}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          margin: "0",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          msTransform: "translate(-50%, -50%)",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Text h4>No Users Found</Text>
                      </div>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button auto flat color="error" onClick={closeHandler}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            ) : (
              <>
                <Modal.Body>
                  <Row justify="center">
                    <Text h3>Friend Requests</Text>
                  </Row>
                  {friendRequests.length === 0 ? (
                    <>
                      <div
                        style={{
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            margin: "0",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            msTransform: "translate(-50%, -50%)",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <Text h4>No Pending Requests</Text>
                        </div>
                      </div>
                    </>
                  ) : (
                    friendRequests.map((c) => <Request userId={c} />)
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button auto flat color="error" onClick={closeHandler}>
                    Close
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>
        </>
      </div>
    ),
  };
};

export default Friends;
