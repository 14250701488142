import Register from "./components/register";
import Login from "./components/login";
import Messenger from "./components/messenger";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import RequireAuth from "./components/RequireAuth";
import Missing from "./pages/NoPage";
import { Routes, Route, Navigate } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import { createTheme, NextUIProvider } from "@nextui-org/react";
import PersistLogin from "./components/PersistLogin";
import "./App.css";

const darkTheme = createTheme({
  type: "dark",
});

const ROLES = {
  User: 69,
  Admin: 420,
};

function App() {
  const darkMode = useDarkMode(true);
  return (
    <NextUIProvider theme={darkTheme}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="signin" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="*" element={<Missing />} />

          {/* we want to protect these routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route path="/" element={<Navigate to="/messenger" />} />
              <Route path="messenger" element={<Messenger />} />
            </Route>
          </Route>

          <Route
            element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}
          ></Route>
        </Route>
      </Routes>
    </NextUIProvider>
  );
}

export default App;
