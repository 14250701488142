import React from "react";
import { useEffect, useState, useContext } from "react";
import {
  Card,
  Avatar,
  Spacer,
  Text,
  Button,
  Grid,
  Tooltip,
} from "@nextui-org/react";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import useAuth from "../../hooks/useAuth";

const Request = ({ userId }) => {
  const { auth } = useContext(AuthContext);
  const { setAuth } = useAuth();

  const [user, setUser] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnTxt, setBtnTxt] = useState("Accept Request");
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get("/api/users?userId=" + userId);
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, [userId]);

  const acceptRequestHandler = async () => {
    try {
      console.log("triggerd accept", userId, auth.userId);
      const res = await axios.post(
        "/acceptRequest?sender=" + userId + "&receiver=" + auth.userId
      );
      setBtnDisable(true);
      setBtnTxt("Request Accepted");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card variant="flat" css={{ w: "stretch", $$cardColor: "#10253E" }}>
      <Card.Header>
        <Grid.Container gap={0} justify="space-evenly">
          <Grid>
            <Avatar
              squared
              src="https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg"
            />
          </Grid>
          <Grid xl>
            <Button light disabled auto>
              <Text h4 b>
                {user.username}
              </Text>
            </Button>
          </Grid>
          <Grid>
            <Button
              disabled={btnDisable}
              onClick={() => acceptRequestHandler()}
              auto
            >
              {btnTxt}
            </Button>
          </Grid>
        </Grid.Container>
      </Card.Header>
    </Card>
  );
};

export default Request;
