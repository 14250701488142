import React from "react";
import { useRef, useState, useEffect } from "react";
import image from "./../assets/images/background.jpg";
import axios from "../api/axios";
import {
  Link,
  NextUIProvider,
  Button,
  Card,
  Grid,
  Text,
  Input,
  Spacer,
  Container,
  Row,
  Col,
} from "@nextui-org/react";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*\d).{8,}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const REGISTER_URL = "/register";

function Register() {
  const userRef = useRef();
  const errRef = useRef();

  const [userError, setUserError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userPwdError, setPwdError] = useState("");
  const [isDisabled, setDisabled] = useState(false);

  const [user, setUser] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState("");
  const [validemail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = EMAIL_REGEX.test(email);
    const v3 = PWD_REGEX.test(pwd);
    if (!v1) {
      setUserError("Enter Valid Username");
    } else {
      setUserError("");
    }
    if (!v2) {
      setEmailError("Invalid Email");
    } else {
      setEmailError("");
    }
    if (!v3) {
      setPwdError("Enter a strong password!");
    } else {
      setPwdError("");
    }
    if (!v1 || !v2 || !v3) {
      return;
    }

    try {
      setDisabled(true);
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({ user, email, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(response?.data);
      console.log(response?.accessToken);
      console.log(JSON.stringify(response));
      setSuccess(true);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUser("");
      setPwd("");
      setEmail("");
      setMatchPwd("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username or Email already taken");
      } else {
        setErrMsg(`Registration Failed Code ${err.response?.status}`);
      }
      setDisabled(false);
      errRef.current.focus();
    }
  };

  return (
    <>
      {success ? (
        <div
          className="div"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            height: "100vh",
            position: "static",
          }}
        >
          <div
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                margin: "0",
                position: "absolute",
                top: "50%",
                left: "50%",
                msTransform: "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Container>
                <Card
                  css={{
                    padding: "$15",
                    margin: "auto",
                    justifySelf: "center",
                    maxWidth: "fit-content",
                    backgroundColor: "#202225",
                  }}
                >
                  <Card.Header>
                    <Grid.Container justify="center">
                      <Text h1>Registration Successful!</Text>
                    </Grid.Container>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Container gap={2} justify="center">
                      <Grid>
                        <Link css={{ fontSize: "$L" }} href="/signin">
                          Sign In
                        </Link>
                      </Grid>
                    </Grid.Container>
                  </Card.Body>
                </Card>
              </Container>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="div"
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            height: "100vh",
            position: "static",
          }}
        >
          <div
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                margin: "0",
                position: "absolute",
                top: "50%",
                left: "50%",
                msTransform: "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Container>
                <Card
                  css={{
                    padding: "$15",
                    margin: "auto",
                    justifySelf: "center",
                    maxWidth: "fit-content",
                    backgroundColor: "#202225",
                  }}
                >
                  <Card.Header>
                    <Grid.Container justify="center">
                      <Text h1>Register</Text>
                    </Grid.Container>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Container gap={2} justify="center">
                      <Grid>
                        <Text color="error">{errMsg}</Text>
                      </Grid>
                    </Grid.Container>
                    <Grid.Container gap={2} justify="center">
                      <Grid>
                        <Input
                          id="username"
                          ref={userRef}
                          onChange={(e) => setUser(e.target.value)}
                          value={user}
                          helperColor="error"
                          helperText={userError}
                          css={{ width: "$60" }}
                          label="Username"
                        />
                      </Grid>
                    </Grid.Container>
                    <Grid.Container gap={2} justify="center">
                      <Grid>
                        <Input
                          id="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          helperColor="error"
                          helperText={emailError}
                          css={{ width: "$60" }}
                          label="Email"
                        />
                      </Grid>
                    </Grid.Container>
                    <Grid.Container gap={2} justify="center">
                      <Grid>
                        <Input.Password
                          id="password"
                          onChange={(e) => setPwd(e.target.value)}
                          value={pwd}
                          helperColor="error"
                          helperText={userPwdError}
                          css={{ width: "$60" }}
                          label="Password"
                        />
                      </Grid>
                    </Grid.Container>
                    <Spacer></Spacer>
                    <Grid.Container gap={2} justify="center">
                      <Grid>
                        <Button disabled={isDisabled} onClick={handleSubmit}>
                          Sign Up
                        </Button>
                      </Grid>
                    </Grid.Container>
                    <Grid.Container gap={2} justify="center">
                      <Grid>
                        <Link css={{ fontSize: "$xs" }} href="/signin">
                          Already have an account? Sign In
                        </Link>
                      </Grid>
                    </Grid.Container>
                  </Card.Body>
                </Card>
              </Container>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Register;
