import React from "react";
import axios from "../../api/axios";
import { useEffect, useState } from "react";
import { Card, Avatar, Spacer, Text } from "@nextui-org/react";

const Conversations = ({ conversation, currentUser }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const friendId = conversation.members.find((m) => m !== currentUser.userId);

    const getUser = async () => {
      try {
        const res = await axios("/api/users?userId=" + friendId);
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, [currentUser, conversation]);

  return (
    <Card
      isPressable
      variant="shadow"
      css={{ w: "stretch", $$cardColor: "#10253E" }}
      color="blue"
    >
      <Card.Header>
        <Avatar
          squared
          pointer
          src="https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg"
        />
        <Spacer x={0.5} />
        <Text b>{user?.username}</Text>
      </Card.Header>
    </Card>
  );
};

export default Conversations;
