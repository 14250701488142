import React from "react";
import { useEffect, useState, useContext } from "react";
import {
  Card,
  Avatar,
  Spacer,
  Text,
  Button,
  Grid,
  Tooltip,
} from "@nextui-org/react";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

const Unfriend = ({ userId }) => {
  const { auth } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  const [btnTxt, setBtnText] = useState("Unfriend");
  useEffect(() => {
    const getuserDetails = async () => {
      try {
        const res = await axios.get("/api/users?userId=" + userId);
        setUsername(res.data.username);
      } catch (err) {
        console.log(err);
      }
    };
    getuserDetails();
  }, [userId]);
  const clickHandler = async () => {
    try {
      const res = await axios.post(
        "/unfriend?sender=" + auth.userId + "&receiver=" + userId
      );
      console.log("unfriended");
      setBtnStatus(true);
      setBtnText("Removed");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card variant="flat" css={{ w: "stretch", $$cardColor: "#10253E" }}>
      <Card.Header>
        <Grid.Container gap={0} justify="space-evenly">
          <Grid>
            <Avatar
              squared
              src="https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg"
            />
          </Grid>
          <Grid xl>
            <Button light disabled auto>
              <Text h4 b>
                {username}
              </Text>
            </Button>
          </Grid>
          <Grid>
            <Button
              disabled={btnStatus}
              color={"error"}
              auto
              onClick={() => clickHandler()}
            >
              {btnTxt}
            </Button>
          </Grid>
        </Grid.Container>
      </Card.Header>
    </Card>
  );
};

export default Unfriend;
