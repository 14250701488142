import React from "react";
import { useState, useEffect } from "react";
import axios from "../../api/axios";
import { Card, Avatar, Spacer, Text } from "@nextui-org/react";

const FriendConvo = ({ userId }) => {
  const [username, setUsername] = useState("");
  useEffect(() => {
    const getuserDetails = async () => {
      try {
        const res = await axios.get("/api/users?userId=" + userId);
        setUsername(res.data.username);
      } catch (err) {
        console.log(err);
      }
    };
    getuserDetails();
  }, [userId]);
  return (
    <Card
      isPressable
      variant="shadow"
      css={{ w: "stretch", $$cardColor: "#10253E" }}
      color="blue"
    >
      <Card.Header>
        <Avatar
          squared
          pointer
          src="https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg"
        />
        <Spacer x={1} />
        <Text b>{username}</Text>
      </Card.Header>
    </Card>
  );
};

export default FriendConvo;
