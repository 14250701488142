import React from "react";
import { useEffect, useState, useContext } from "react";
import {
  Card,
  Avatar,
  Spacer,
  Text,
  Button,
  Grid,
  Tooltip,
} from "@nextui-org/react";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

const Friend = ({ user, friendList, setSuccess }) => {
  const { auth } = useContext(AuthContext);
  const [btnTxt, setBtnTxt] = useState("Send Friend Request");
  const [btnStatus, setBtnStatus] = useState(false);
  const friendRequest = async () => {
    try {
      const res = await axios.post(
        "/friendRequest?sender=" + auth.userId + "&receiver=" + user._id
      );
      console.log("friend req sent");
      setBtnTxt("Sent");
      setBtnStatus(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card variant="flat" css={{ w: "stretch", $$cardColor: "#10253E" }}>
      <Card.Header>
        <Grid.Container gap={0} justify="space-evenly">
          <Grid>
            <Avatar
              squared
              src="https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg"
            />
          </Grid>
          <Grid xl>
            <Button light disabled auto>
              <Text h4 b>
                {user.username}
              </Text>
            </Button>
          </Grid>
          <Grid>
            {!friendList.includes(user._id) ? (
              <Button disabled={btnStatus} auto onClick={() => friendRequest()}>
                {btnTxt}
              </Button>
            ) : (
              <Button disabled={true} auto>
                Already Added
              </Button>
            )}
          </Grid>
        </Grid.Container>
      </Card.Header>
    </Card>
  );
};

export default Friend;
