import React from "react";
import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "../../context/AuthProvider";
import {
  faSearch,
  faUserGroup,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  Text,
  Input,
  Row,
  Checkbox,
  Spacer,
  Table,
  useAsyncList,
} from "@nextui-org/react";
import axios from "../../api/axios";
import FriendConvo from "./FriendConvo";
import ConversationContext from "../../context/ConversationContext";

const NewConversationCard = () => {
  const { addConversation } = useContext(ConversationContext);
  const { auth } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const newConvoHandler = () => setVisible(true);
  const [mode, setMode] = useState(1);
  const [chat, setChat] = useState(null);
  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };

  const [friendList, setFriendList] = useState([]);
  useEffect(() => {
    const getFriendList = async () => {
      try {
        const res = await axios.get("/api/users?userId=" + auth.userId);
        setFriendList(res.data.friends);
      } catch (err) {
        console.log(err);
      }
    };
    getFriendList();
  }, [visible]);

  const friendClickHandler = async (Id) => {
    // check if conversation exists, if not then create

    try {
      const res = await axios.get(
        "/api/conversations/find/" + auth.userId + "/" + Id
      );
      console.log(res.data);
      if (res.data) {
        setChat(res.data);
        addConversation(res.data);
      } else {
        const response = await axios.post(
          "/api/conversations/",
          JSON.stringify({ senderId: auth.userId, receiverId: Id }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        setChat(response.data);
        addConversation(response.data);
      }
    } catch (err) {
      console.log(err);
    }

    // close

    closeHandler();
  };

  return {
    chat,
    newConvoHandler,
    newConversion: (
      <div>
        <>
          <Modal
            closeButton
            width="50vh"
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
            css={{ maxHeight: "80vh", minHeight: "50vh" }}
          >
            <Modal.Header>
              <Row justify="center">
                <Text h3>Open Conversation</Text>
              </Row>
            </Modal.Header>

            <Modal.Body>
              {/* <Input
                clearable
                bordered
                fullWidth
                color="primary"
                placeholder="Search friends"
              /> */}
              {friendList.length === 0 ? (
                <>
                  <div
                    style={{
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        msTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Text h4>No Friends Found</Text>
                    </div>
                  </div>
                </>
              ) : (
                friendList.map((c) => (
                  <div onClick={() => friendClickHandler(c)}>
                    <FriendConvo userId={c} />
                  </div>
                ))
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button auto flat color="error" onClick={closeHandler}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    ),
  };
};

export default NewConversationCard;
