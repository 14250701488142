import React from "react";
import { useContext, useState, useRef, useEffect } from "react";
import {
  Card,
  Grid,
  Text,
  Button,
  Row,
  Avatar,
  Spacer,
  Input,
  Col,
} from "@nextui-org/react";
import { SendButton } from "./sendButton/SendButton";
import { SendIcon } from "./sendButton/SendIcon";
import Message from "./message";
import AuthContext from "./../../context/AuthProvider";
import axios from "../../api/axios";
import { io } from "socket.io-client";

const RightCard = ({ currentChat, messages, setMessages }) => {
  const { auth } = useContext(AuthContext);
  const [newMessage, setNewMessage] = useState("");
  const [username, setUsername] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [conversations, setConversations] = useState([]);
  const socket = useRef();
  const scrollRef = useRef();
  const [user, setUser] = useState(null);

  useEffect(() => {
    socket.current = io(
      process.env.REACT_APP_SOCKET_URL ||
        "ws://boiling-ridge-81407.herokuapp.com"
    );
    socket.current.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    socket.current.emit("addUser", auth.userId);
  }, [user]);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await axios.get("/conversations/" + auth.userId);
        setConversations(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [auth.userId]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get("/messages/" + currentChat?._id);
        setMessages(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
  }, [currentChat]);

  useEffect(() => {
    if (currentChat) {
      const friendId = currentChat.members.find((m) => m !== auth.userId);
      const getUser = async () => {
        try {
          const res = await axios("/api/users?userId=" + friendId);
          setUsername(res.data.username);
          setUser(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      getUser();
    }
  }, [auth, currentChat]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = async (e) => {
    if (newMessage.trim()) {
      const message = {
        sender: auth.userId,
        text: newMessage,
        conversationId: currentChat._id,
      };

      setMessages([...messages, message]);
      setNewMessage("");

      const receiverId = currentChat.members.find(
        (member) => member !== auth.userId
      );

      socket.current.emit("sendMessage", {
        senderId: auth.userId,
        receiverId,
        text: newMessage,
      });

      try {
        const res = await axios.post("/api/messages", message);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Card variant="bordered" css={{ h: "$24", h: "97vh" }}>
      {currentChat ? (
        <>
          <Card.Header>
            <Avatar
              squared
              src="https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg"
            />
            <Spacer x={1} />
            <Text b>{username}</Text>
          </Card.Header>
          <Card.Divider />
          <Card.Body
            css={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                paddingLeft: "25px",
                bottom: "0px",
                msOverflowY: "auto",
                maxHeight: "100%",
              }}
            >
              {messages.map((m) => (
                <div ref={scrollRef}>
                  <Grid.Container
                    justify={
                      m.sender === auth.userId ? "flex-end" : "flex-start"
                    }
                  >
                    <Grid>
                      <Spacer y={0.5} />
                      <Message own={m.sender === auth.userId} message={m} />
                    </Grid>
                  </Grid.Container>
                </div>
              ))}
            </div>
          </Card.Body>
          <Card.Footer>
            <Input
              status="primary"
              css={{}}
              clearable
              aria-label="send message"
              width="100%"
              contentRightStyling={false}
              onChange={(e) => setNewMessage(e.target.value)}
              value={newMessage}
              placeholder="Type your message..."
              onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
              contentRight={
                <SendButton onClick={() => handleSubmit()}>
                  <SendIcon />
                </SendButton>
              }
            />
          </Card.Footer>
        </>
      ) : (
        <Card.Body>
          <div
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                margin: "0",
                position: "absolute",
                top: "50%",
                left: "50%",
                msTransform: "translate(-50%, -50%)",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Text h3>Open a Conversation</Text>
            </div>
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

export default RightCard;
