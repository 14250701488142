import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUserGroup,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { Key, useState, useEffect, useContext } from "react";
import Conversations from "./conversations";
import AuthContext from "./../../context/AuthProvider";
import axios from "../../api/axios";
import Friends from "../FriendCard/friends";
import NewConversationCard from "../NewConversation/ConversationCard";
import {
  Input,
  Modal,
  Card,
  Grid,
  Text,
  Button,
  Row,
  Spacer,
  Avatar,
  Dropdown,
  Tooltip,
} from "@nextui-org/react";
import ConversationContext from "../../context/ConversationContext";

const LeftCard = () => {
  const [visible, setVisible] = React.useState(false);
  const { friendHandler, friends } = Friends();
  const { chat, newConvoHandler, newConversion } = NewConversationCard();

  const closeHandler = () => {
    setVisible(false);
  };

  const navigate = useNavigate();
  const logout = useLogout();

  const [selected, setSelected] = React.useState(new Set(["text"]));

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(", ").replaceAll("_", " "),
    [selected]
  );

  useEffect(() => {
    if (selectedValue === "logOut") {
      signOut();
    } else if (selectedValue === "settings") {
      setVisible(true);
    }
  }, [selected]);

  const signOut = async () => {
    await logout();
    navigate("/signin");
  };

  useEffect(() => {
    setcurrentChat(chat);
    // if (conversations.includes(chat)) {
    //   console.log("it does");
    //   setConversations(conversations.push(chat));
    // }
  }, [chat]);

  // const [conversations, setConversations] = useState([]);
  const [currentChat, setcurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const { auth } = useContext(AuthContext);
  const { conversations, setConversations, addConversation } =
    useContext(ConversationContext);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const res = await axios.get("/api/conversations/" + auth.userId);
        setConversations(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, [auth.userId, setcurrentChat]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const res = await axios.get("/api/messages/" + currentChat?._id);
        setMessages(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getMessages();
  }, [currentChat]);

  return {
    currentChat,
    messages,
    setMessages,
    leftCard: (
      <>
        {friends}
        {newConversion}
        <div
          style={{
            width: "100vh",
          }}
        >
          <Card variant="bordered" css={{ h: "97vh" }}>
            <Card.Header>
              <Grid.Container gap={0} justify="space-evenly">
                <Grid>
                  <Avatar
                    squared
                    src="https://icon-library.com/images/default-profile-icon/default-profile-icon-24.jpg"
                  />
                </Grid>
                <Grid xl>
                  <Button light disabled auto>
                    <Text h4 b>
                      {auth.username}
                    </Text>
                  </Button>
                </Grid>
                <Grid>
                  <Tooltip content={"New Conversation"} placement="bottom">
                    <Button
                      light
                      auto
                      onClick={() => newConvoHandler()}
                      icon={
                        <FontAwesomeIcon icon={faPlus} css={{ as: "center" }} />
                      }
                    />
                  </Tooltip>
                </Grid>
                <Grid>
                  <Tooltip content={"Friends"} placement="bottom">
                    <Button
                      light
                      auto
                      onClick={() => friendHandler()}
                      icon={
                        <FontAwesomeIcon
                          icon={faUserGroup}
                          css={{ as: "center" }}
                        />
                      }
                    />
                  </Tooltip>
                </Grid>
                <Grid>
                  <Dropdown>
                    <Dropdown.Button light></Dropdown.Button>
                    <Dropdown.Menu
                      disallowEmptySelection
                      selectionMode="single"
                      onSelectionChange={setSelected}
                      variant="light"
                      aria-label="Actions"
                    >
                      {/* <Dropdown.Item key="settings">Settings</Dropdown.Item> */}
                      <Dropdown.Item key="logOut" color="error">
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid>
              </Grid.Container>
            </Card.Header>
            <Card.Divider />
            <Card.Body css={{ py: "$5" }}>
              {/* <Input
                status="primary"
                clearable
                width="100%"
                contentRightStyling={false}
                placeholder="Search Friends"
                contentRight={
                  <Button
                    light
                    auto
                    icon={<FontAwesomeIcon icon={faSearch} />}
                  />
                }
              /> */}
              {conversations.length === 0 ? (
                <>
                  <div
                    style={{
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        margin: "0",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        msTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <Text h4>Start a new Conversation</Text>
                    </div>
                  </div>
                </>
              ) : (
                conversations.map((c) => (
                  <div onClick={() => setcurrentChat(c)}>
                    <Spacer y={0.5} />
                    <Conversations conversation={c} currentUser={auth} />
                  </div>
                ))
              )}
            </Card.Body>
          </Card>

          <Modal
            closeButton
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
          >
            <Modal.Header>
              <Text id="modal-title" size={18}>
                Welcome to
                <Text b size={18}>
                  NextUI
                </Text>
              </Text>
            </Modal.Header>
            <Modal.Body>
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                placeholder="Email"
              />
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                placeholder="Password"
              />
              <Row justify="space-between">
                <Text size={14}>Forgot password?</Text>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button auto flat color="error" onClick={closeHandler}>
                Close
              </Button>
              <Button auto onClick={closeHandler}>
                Sign in
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    ),
  };
};

export default LeftCard;
